<template>
  <div>
    <template>
      <v-container>
        <v-row>
          <v-col cols="12">
            <Subheader icon="mdi-card-text" title="Allgemein"></Subheader>
            <div class="mt-7">
              <v-card>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="3">
                        <v-text-field
                          v-model.number="performanceTargetLocal.sortKey"
                          type="number"
                          clearable
                          label="Sortierschlüssel"
                          :rules="[rules.required]"
                          outlined
                        ></v-text-field
                      ></v-col>
                      <v-col cols="12" sm="3">
                        <v-text-field
                          v-model.trim="performanceTargetLocal.shortTitle"
                          :counter="6"
                          clearable
                          label="Kurzbezeichnung"
                          :rules="[rules.required]"
                          outlined
                        ></v-text-field
                      ></v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          v-model.trim="performanceTargetLocal.title"
                          :counter="32"
                          label="Bezeichnung"
                          required
                          :rules="[rules.required]"
                          outlined
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </div>
          </v-col>
          <v-col cols="12">
            <Subheader icon="mdi-cog" title="Einstellungen"></Subheader>
            <div class="mt-7">
              <v-card>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col>
                        <v-switch
                          v-model="performanceTargetLocal.isActive"
                          dense
                          inset
                          label="Aktive Zielvorgabe"
                          class="mt-0"
                        ></v-switch>
                      </v-col>
                    </v-row>
                    <v-row>Verwendung zulässig ab:</v-row>
                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="performanceTargetLocal.validFrom.date"
                          type="date"
                          label="Startdatum"
                          hint="DD.MM.YYYY"
                          outlined
                          :rules="[rules.required, rules.startBeforeEnd]"
                          required
                          prepend-inner-icon="mdi-calendar-start"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="3">
                        <v-text-field
                          v-model="performanceTargetLocal.validFrom.time"
                          label="Startzeit"
                          hint="HH:MM"
                          :rules="[rules.required, rules.startBeforeEnd]"
                          outlined
                          type="time"
                          prepend-inner-icon="mdi-clock-start"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>Verwendung zulässig bis:</v-row>
                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="performanceTargetLocal.validUntil.date"
                          type="date"
                          label="Enddatum"
                          hint="DD.MM.YYYY"
                          outlined
                          :rules="[rules.required, rules.startBeforeEnd]"
                          required
                          prepend-inner-icon="mdi-calendar-end"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="3">
                        <v-text-field
                          v-model="performanceTargetLocal.validUntil.time"
                          label="Endzeit"
                          hint="HH:MM"
                          outlined
                          :rules="[rules.required, rules.startBeforeEnd]"
                          type="time"
                          prepend-inner-icon="mdi-clock-end"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>Anwendbar auf:</v-row>
                    <v-row>
                      <v-col cols="12" sm="6" md="6">
                        <v-select
                          v-model="performanceTargetLocal.applicableDivisionIds"
                          :items="divisions"
                          label="Abteilung/Einheit"
                          outlined
                          multiple
                          :rules="[rules.required]"
                          item-value="meta.id"
                          item-text="title"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row>Betrachtungszeitraum:</v-row>
                    <v-row>
                      <v-col cols="12" sm="6" md="6">
                        <v-select
                          v-model="
                            performanceTargetLocal.monitoringInterval.unit
                          "
                          :items="monitoringIntervalTypes"
                          label="Typ"
                          outlined
                          :rules="[rules.required]"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          v-model.number="
                            performanceTargetLocal.monitoringInterval.value
                          "
                          type="number"
                          label="Wert"
                          outlined
                          :rules="[rules.required]"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </div>
          </v-col>
          <v-col cols="12">
            <Subheader icon="mdi-variable" title="Variablen"></Subheader>
            <div class="mt-7">
              <v-card>
                <v-card-text
                  >Hinweis: Als Datengrundlage können lediglich Dienstberichte
                  und/oder Stundenmeldungen dienen, die sich im Status "Zu
                  prüfen", "Genehmigt" oder "Festgeschrieben"
                  befinden.</v-card-text
                >
                <v-card-text
                  v-if="performanceTargetLocal.dataVariables.length > 0"
                >
                  <v-row
                    v-for="(
                      dataVariable, index
                    ) in performanceTargetLocal.dataVariables"
                    :key="index"
                    dense
                  >
                    <v-col cols="12" sm="2" md="2">
                      <v-text-field
                        v-model="dataVariable.variable.text"
                        dense
                        outlined
                        disabled
                        :hint="'ID:' + dataVariable.variable.id"
                        persistent-hint
                        label="Variable"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" md="4">
                      <v-select
                        v-model="dataVariable.sourceType"
                        dense
                        label="Datenquelle"
                        small-chips
                        outlined
                        item-key="id"
                        hide-details="auto"
                        :items="variableTypes"
                        return-object
                        ><template v-slot:item="{ item }">
                          <v-list-item-content>
                            <v-list-item-title class="font-weight-medium">{{
                              item.text
                            }}</v-list-item-title>
                            <v-list-item-subtitle
                              ><span class="font-weight-regular">{{
                                item.description
                              }}</span></v-list-item-subtitle
                            >
                          </v-list-item-content>
                        </template>
                      </v-select>
                    </v-col>
                    <v-col cols="12" sm="5" md="5">
                      <v-select
                        v-model="dataVariable.value"
                        :items="
                          getFilters(
                            dataVariable.sourceType.id,
                            dataVariable.variable.id
                          )
                        "
                        item-key="id"
                        return-object
                        dense
                        label="Filter/Wert"
                        outlined
                        multiple
                        small-chips
                        clearable
                        hide-details="auto"
                      >
                      </v-select
                    ></v-col>
                    <v-btn
                      text
                      depressed
                      class="mt-2"
                      color="error"
                      @click="deleteDataVariable(index)"
                      ><v-icon>mdi-delete</v-icon></v-btn
                    >
                  </v-row>
                </v-card-text>
                <v-divider
                  v-if="performanceTargetLocal.dataVariables.length"
                ></v-divider>
                <v-card-actions class="px-4 pb-4">
                  <v-row dense>
                    <v-col cols="4">
                      <v-form
                        ref="newVariableFormRef"
                        v-model="newVariableFormIsValid"
                      >
                        <v-text-field
                          v-model="newVariableName"
                          dense
                          outlined
                          :rules="[rules.required, rules.uniqueVariableName]"
                          label="Bezeichnung für Variable vergeben"
                          hide-details="auto"
                        ></v-text-field>
                      </v-form>
                    </v-col>
                    <v-col>
                      <v-btn
                        text
                        small
                        class="ml-2 my-2"
                        @click="addDataVariable"
                        :disabled="!newVariableFormIsValid"
                        ><v-icon left>mdi-plus</v-icon>Variable
                        hinzufügen</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-card-actions>
              </v-card>
            </div>
          </v-col>
          <v-col cols="12">
            <Subheader
              icon="mdi-arrow-decision"
              title="Regeleditor"
            ></Subheader>
            <div class="mt-7">
              <condition-builder
                :conditionsData="performanceTargetLocal.conditions"
                :dataVariables="performanceTargetLocal.dataVariables"
              >
              </condition-builder>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </div>
</template>

<script>
import Subheader from "@/components/_systemwide/Subheader.vue";
import ConditionBuilder from "@/components/member-management/performance-targets/ConditionBuilder.vue";
import { DUTY_MANAGEMENT, MEMBER_MANAGEMENT } from "@/store/modules.js";
import { GET_REPORT_TYPES, GET_DIVISIONS } from "@/store/action-types.js";
export default {
  name: "performance-target-editor",
  props: {
    performanceTarget: {},
    mode: {
      type: String,
      default: "new",
      required: true,
      validator: function (value) {
        return ["new", "edit"].indexOf(value) !== -1;
      },
    },
  },
  components: {
    Subheader,
    ConditionBuilder,
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || "Feld ist erforderlich.",
        uniqueVariableName: (value) => {
          if (
            !this.performanceTargetLocal ||
            !this.performanceTargetLocal.dataVariables
          ) {
            return true; // or false, depending on your desired behavior when dataVariables is not defined
          }
          return (
            this.performanceTargetLocal.dataVariables.filter(
              (variable) => variable.variable.text === value
            ).length === 0 || "Bezeichnung bereits vergeben."
          );
        },
      },
      newVariableName: "",
      newVariableFormIsValid: false,
      a: {
        type: "operation",
        leftArgument: {
          type: "variable",
          variableId: "var_id_8c296bf79c9da7afc1a2",
        },
        operation: "==",
        rightArgument: {
          type: "constant",
          value: { type: "ChoiceId", value: "choice_id_7e3eafc19ba46dd72c3d" },
        },
      },
      variableTypes: [
        {
          text: "Summe aus mehreren Datenquellen",
          id: "sumFromMultipleDataSources",
          description: "Summe aus mehreren zuvor (!) definierten Datenquellen",
        },
        {
          text: "Summe Dienstteilnahme in min",
          id: "totalDutyTimeInMin",
          description: "Berechnet aus Dienstberichten",
        },
        {
          text: "Summe Dienstteilnahme in h",
          id: "totalDutyTimeInHours",
          description: "Berechnet aus Dienstberichten",
        },
        {
          text: "Summe Dienstteilnahme Anzahl",
          id: "totalDutyTimeCount",
          description: "Berechnet aus Dienstberichten",
        },
        {
          text: "Summe Stundenmeldungen in min",
          id: "totalTimeSheetsInMin",
          description: "Berechnet aus Stundenmeldungen",
        },
        {
          text: "Summe Stundenmeldungen in h",
          id: "totalTimeSheetsInHours",
          description: "Berechnet aus Stundenmeldungen",
        },
        {
          text: "Summe Stundenmeldungen Anzahl",
          id: "totalTimeSheetsCount",
          description: "Berechnet aus Stundenmeldungen",
        },
        {
          text: "Summe Funktionsausübung in min",
          id: "totalPositionTimeInMin",
          disabled: true,
        },
        {
          text: "Summe Einsatzmittelbesetzung in min",
          id: "totalUnitTimeInMin",
          disabled: true,
        },
      ],
      monitoringIntervalTypes: [
        { text: "Vorherige Tage", value: "days" },
        { text: "Laufende Wochen", value: "weeks" },
        { text: "Vorherige Wochen + aktuelle Woche", value: "weeks+current" },
        { text: "Laufende Monate", value: "months" },
        { text: "Vorherige Monate + aktueller Monat", value: "months+current" },
      ],
    };
  },
  model: {
    prop: "performanceTarget",
    event: "performanceTargetChange",
  },
  computed: {
    performanceTargetLocal: {
      get: function () {
        return this.performanceTarget;
      },
      set: function (value) {
        this.$emit("performanceTargetChange", value);
      },
    },
    divisions() {
      return this.$store.state.memberManagement.divisions;
    },
    dutyTypes() {
      const dutyType = this.$store.state.dutyManagement.dutyTypes;
      return dutyType.sort((a, b) => a.sortKey - b.sortKey);
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.$store.dispatch(`${MEMBER_MANAGEMENT}${GET_DIVISIONS}`, {
        organizationId: this.$route.params.organizationId,
      });
      this.$store.dispatch(`${DUTY_MANAGEMENT}${GET_REPORT_TYPES}`, {
        organizationId: this.$route.params.organizationId,
      });
    },
    addDataVariable() {
      const nextDatavariableId =
        "var_id_" + Math.random().toString(36).substr(2, 9);
      this.performanceTargetLocal.dataVariables.push({
        variable: {
          id: nextDatavariableId,
          text: this.newVariableName,
        },
        sourceType: { text: null, id: null },
        value: [],
      });
      this.newVariableName = "";
      this.$refs.newVariableFormRef.resetValidation();
    },
    deleteDataVariable(index) {
      this.performanceTargetLocal.dataVariables.splice(index, 1);
    },
    getFilters(dataVariableTypeId, currentVariableId) {
      switch (dataVariableTypeId) {
        case "sumFromMultipleDataSources":
          return this.performanceTargetLocal.dataVariables
            .map((dataVariable) => {
              return {
                id: dataVariable.variable.id,
                text: dataVariable.variable.text,
              };
            })
            .filter((variable) => variable.id !== currentVariableId);
        case "totalDutyTimeInMin":
        case "totalDutyTimeInHours":
        case "totalDutyTimeCount":
          return this.dutyTypes.map((dutyType) => {
            return {
              id: dutyType.meta.id,
              text: dutyType.title,
            };
          });
        case "totalTimeSheetsInMin":
        case "totalTimeSheetsInHours":
        case "totalTimeSheetsCount":
          return [
            {
              id: "dutyTime",
              text: "Einsatzdienst",
            },
            {
              id: "courseTime",
              text: "Lehrgang/Fortbildung",
            },
            {
              id: "otherTime",
              text: "Sonstige Stunden",
            },
          ];
        default:
          return [];
      }
    },
  },
};
</script>
