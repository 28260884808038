<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="results"
      item-key="user.uid"
      :items-per-page="25"
      :footer-props="footerProps"
      :custom-filter="filter"
      sort-by="user.lastName"
      :search="search"
      :group-by.sync="groupBySelector.value"
      class="rounded"
      max-height="800px auto"
      :mobile-breakpoint="0"
      dense
    >
      <template v-slot:group.header="{ items, isOpen, toggle }">
        <th :colspan="headers.length">
          <div class="my-1">
            <v-icon @click="toggle" class="mr-1"
              >{{ isOpen ? "mdi-chevron-up" : "mdi-chevron-down" }}
            </v-icon>
            <span v-if="groupBySelector.value === 'groupByStatus'">
              {{ stripPrefix(items[0].groupByStatus) }}
            </span>
            <span v-else-if="groupBySelector.value === 'groupByGroup'">
              {{ stripPrefix(items[0].groupByGroup) }}
            </span>
          </div>
        </th>
      </template>
      <template v-slot:item="{ item }">
        <tr class="parent-tr">
          <td class="inner-td">
            {{ item.user.firstName }}
          </td>
          <td
            class="inner-td"
            style="border-right: thin solid rgba(0, 0, 0, 0.12) !important"
          >
            {{ item.user.lastName }}
          </td>
          <td style="border-right: thin solid rgba(0, 0, 0, 0.12) !important">
            <performance-target-status-chip
              :status="item.totalPerformanceTargetResultStatus"
              small
            ></performance-target-status-chip>
          </td>
          <td
            v-for="(result, index) in item.performanceTargetResults"
            :key="index"
            :class="getCellClassByStatus(result.status)"
            style="border-right: thin solid rgba(0, 0, 0, 0.12) !important"
          >
            <v-chip
              v-for="variable in result.dataVariables"
              :key="variable.id"
              small
              outlined
            >
              {{ variable.variable.text }}: {{ variable.value }}
            </v-chip>
          </td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import PerformanceTargetStatusChip from "@/components/member-management/performance-targets/PerformanceTargetStatusChip.vue";
export default {
  name: "performance-targets-results-data-table",
  props: {
    results: {
      type: Array,
      required: true,
    },
    search: {
      type: String,
      required: false,
    },
    groupBySelector: {
      type: Object,
      required: true,
    },
  },
  components: { PerformanceTargetStatusChip },
  data() {
    return {
      helpers: {
        dataIsLoading: false,
      },
      footerProps: {
        "items-per-page-options": [15, 25, 50, 100, -1],
      },
    };
  },
  computed: {
    performanceTargets() {
      return this.$store.state.memberManagement.performanceTargets;
    },
    headers() {
      const performanceTargetHeaders = this.performanceTargets.map(
        (target) => ({
          text: target.shortTitle,
          value: target.id,
          divider: true,
        })
      );
      return [
        {
          text: "Vorname",
          value: "user.firstName",
          cellClass: "v-row-group__header",
        },
        {
          text: "Nachname",
          value: "user.lastName",
          cellClass: "v-row-group__header",
          divider: true,
        },
        {
          text: "Status",
          value: "totalPerformanceTargetResultStatus",
          divider: true,
        },
        ...performanceTargetHeaders,
      ];
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {},
    filter(value, search, item) {
      if (!search) return true;
      const searchLower = search.toLowerCase();
      return Object.values(item).some((prop) => {
        if (typeof prop === "object" && prop !== null) {
          return Object.values(prop).some((subProp) =>
            String(subProp).toLowerCase().includes(searchLower)
          );
        }
        return String(prop).toLowerCase().includes(searchLower);
      });
    },
    stripPrefix(item) {
      return item.substring(item.indexOf("_") + 1);
    },
    getCellClassByStatus(status) {
      return {
        "green lighten-4 green--text text--darken-4": status === "met",
        "orange lighten-4 orange--text text--darken-4": status === "unknown",
        "red lighten-4 red--text text--darken-4": status === "notMet",
      };
    },
  },
};
</script>
