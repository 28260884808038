<template>
  <div>
    <section>
      <template>
        <v-container fluid>
          <v-row>
            <v-col cols="12" sm="12" md="6" lg="4" hidden>
              <self-service-performance-monitoring
                title="Mein Erfüllungsstatus"
                hideDetailsBtn
              ></self-service-performance-monitoring>
            </v-col>
            <v-col cols="12">
              <v-card>
                <v-card-subtitle>
                  <v-row>
                    <v-col cols="4" sm="5" md="3">
                      <v-text-field
                        v-model="search"
                        outlined
                        append-icon="mdi-magnify"
                        label="Suchen"
                        hide-details="auto"
                        clearable
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col class="d-flex justify-end"
                      ><v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn outlined text v-bind="attrs" v-on="on">
                            <span>
                              Gruppieren nach
                              <strong class="hidden-sm-and-down">{{
                                groupBySelector.text
                              }}</strong>
                              <v-icon small class="hidden-md-and-up">{{
                                groupBySelector.icon
                              }}</v-icon>
                            </span>
                            <v-icon right>mdi-menu-down</v-icon>
                          </v-btn>
                        </template>
                        <v-list nav>
                          <v-list-item-group
                            v-model="groupBySelector"
                            mandatory
                          >
                            <v-list-item
                              link
                              dense
                              :value="{
                                text: 'Zielvorgabe',
                                value: 'groupByStatus',
                                icon: 'mdi-bullseye-arrow',
                              }"
                            >
                              <v-list-item-icon>
                                <v-icon dense>mdi-bullseye-arrow</v-icon>
                              </v-list-item-icon>
                              <v-list-item-title
                                >Zielvorgabe
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item
                              link
                              dense
                              :value="{
                                text: 'Benutzergruppe',
                                value: 'groupByGroup',
                                icon: 'mdi-account-group',
                              }"
                            >
                              <v-list-item-icon>
                                <v-icon dense>mdi-account-group</v-icon>
                              </v-list-item-icon>
                              <v-list-item-title
                                >Benutzergruppe</v-list-item-title
                              >
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                      </v-menu>
                      <v-btn-toggle
                        dense
                        v-model="helpers.displayMode"
                        mandatory
                        class="ml-2"
                      >
                        <v-btn outlined icon value="list">
                          <v-icon
                            :color="$vuetify.theme.dark ? '' : 'grey darken-1'"
                            >mdi-view-sequential-outline</v-icon
                          >
                        </v-btn>
                        <v-btn depressed outlined icon value="table">
                          <v-icon
                            :color="$vuetify.theme.dark ? '' : 'grey darken-1'"
                            >mdi-table</v-icon
                          >
                        </v-btn>
                      </v-btn-toggle>
                    </v-col>
                  </v-row>
                </v-card-subtitle>
                <v-divider v-if="helpers.displayMode === 'table'"></v-divider>
                <performance-targets-results-list
                  v-if="helpers.displayMode === 'list'"
                  :results="performanceTargetsWithResults"
                  :search="search"
                  :group-by-selector="groupBySelector"
                ></performance-targets-results-list>
                <performance-targets-results-data-table
                  v-else-if="helpers.displayMode === 'table'"
                  :results="performanceTargetsWithResults"
                  :search="search"
                  :group-by-selector="groupBySelector"
                ></performance-targets-results-data-table>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
    <section v-if="checkIfSupport">
      <support-tools :sources="code"></support-tools>
    </section>
  </div>
</template>

<script>
// import { db, Timestamp } from "@/firebase";
import { ADMIN, MEMBER_MANAGEMENT } from "@/store/modules.js";
import {
  GET_USER_LIST,
  GET_PERFORMANCE_TARGETS,
  GET_PERFORMANCE_TARGET_RESULTS,
} from "@/store/action-types.js";
import SupportTools from "@/components/_system/helpers/SupportTools.vue";
import SelfServicePerformanceMonitoring from "@/components/self-service/SelfServicePerformanceMonitoring.vue";
import PerformanceTargetsResultsList from "@/components/member-management/performance-targets/PerformanceTargetsResultsList.vue";
import PerformanceTargetsResultsDataTable from "@/components/member-management/performance-targets/PerformanceTargetsResultsDataTable.vue";
export default {
  name: "member-management-performance-targets-matrix",
  components: {
    SupportTools,
    SelfServicePerformanceMonitoring,
    PerformanceTargetsResultsList,
    PerformanceTargetsResultsDataTable,
  },
  data() {
    return {
      helpers: {
        initialLoading: true,
        displayMode: "list",
      },
      groupBySelector: {
        text: "Benutzergruppe",
        value: "groupByGroup",
        icon: "mdi-account-group",
      },
      search: "",
    };
  },
  computed: {
    performanceTargets() {
      return this.$store.state.memberManagement.performanceTargets;
    },
    performanceTargetResults() {
      // this contains per user one result document with the following structure:
      // {
      //   user: { uid: string },
      //   totalPerformanceTargetStatus: "string"
      //   performanceTargetResults: [ { performanceTarget: { id: "string"}, value: number, status: "string"} ]
      // }

      return this.$store.state.memberManagement.performanceTargetResults;
    },
    performanceTargetsWithResults() {
      return this.memberships.map((membership) => {
        const result = this.performanceTargetResults.find(
          (result) => result.user.uid === membership.user.uid
        );

        const performanceTargetResults = {};
        if (result) {
          result.performanceTargets.forEach((targetResult) => {
            performanceTargetResults[targetResult.performanceTarget.id] = {
              ...targetResult,
            };
          });
        }

        return {
          ...membership,
          totalPerformanceTargetResultStatus: result
            ? result.totalPerformanceTargetResultStatus
            : "unknown",
          performanceTargetResults,
          groupByGroup:
            membership.group.sortKey.toString().padStart(4, "0") +
            "_" +
            membership.group.title,
          groupByStatus: "notMet",
        };
      });
    },
    memberships() {
      return this.$store.state.admin.membershipsPublic;
    },
    // membershipsPrepared() {
    //   var range = {
    //     start: this.event.duration.start.timestampToDate,
    //     end: this.event.duration.end.timestampToDate,
    //   };
    //   var membershipsFiltered = this.memberships.filter((obj) => {
    //     return (
    //       new Date(obj.user.memberSinceToDate) <= new Date(range.start) && // All members that where members before or at the start of this event
    //       !(new Date(obj.user.memberUntilToDate) <= new Date(range.end)) && // All members that didn't leave before or at the event of this event
    //       obj.access.type === "member"
    //     );
    //   });

    //   var memberships = membershipsFiltered;
    //   // if (this.event.status !== "planned") {}
    //   const result = memberships.map((item) => {
    //     const container = {
    //       groupByGroup:
    //         item.group.sortKey.toString().padStart(4, "0") +
    //         "_" +
    //         item.group.title,
    //       groupByDivision:
    //         item.user.division.sortKey.toString().padStart(4, "0") +
    //         "_" +
    //         item.user.division.title,
    //       group: {
    //         title: item.group.title,
    //         sortKey: item.group.sortKey,
    //         id: item.group.id,
    //       },
    //       user: {
    //         displayName: item.user.displayName,
    //         uid: item.user.uid,
    //         // photoURL = item.user.photoURL,
    //         // position = item.user.dutyPosition,
    //       },
    //     };
    //     return container;
    //   });
    //   return result;
    // },
    checkIfSupport() {
      return this.$store.getters["user/checkIfSupport"];
    },
    code() {
      return [
        {
          title: "performanceTargetsWithResults",
          data: this.performanceTargetsWithResults,
        },
        { title: "memberships", data: this.memberships },
      ];
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      await this.$store.dispatch(`${ADMIN}${GET_USER_LIST}`, {
        organizationId: this.$route.params.organizationId,
        queryIsFiltered: true,
        query: {
          type: "active",
        },
      });
      await this.$store.dispatch(
        `${MEMBER_MANAGEMENT}${GET_PERFORMANCE_TARGETS}`,
        {
          organizationId: this.$route.params.organizationId,
        }
      );
      await this.$store.dispatch(
        `${MEMBER_MANAGEMENT}${GET_PERFORMANCE_TARGET_RESULTS}`,
        {
          organizationId: this.$route.params.organizationId,
        }
      );

      setTimeout(() => {
        this.helpers.initialLoading = false;
      }, 1);
    },

    sortedTeamMembers(userGroup) {
      return this.memberships
        .filter((member) => member.group.id === userGroup.id)
        .sort((a, b) => a.user.displayName.localeCompare(b.user.displaName));
    },
    statusText(statusId) {
      switch (statusId) {
        case "met":
          return "Zielvorgabe erfüllt";
        case "notMet":
          return "Zielvorgabe nicht erfüllt";
        case "metByIndividualRuling":
          return "Zielvorgabe erfüllt (EA)";
        case "notMetByIndividualRuling":
          return "Zielvorgabe nicht erfüllt (EA)";
        case "fullySuspended":
          return "Zielvorgabe vollständig ausgesetzt";
        case "notStarted":
          return "Zielvorgabe nicht begonnen";
        case "temporarilySuspended":
          return "Zielvorgabe zeitweise ausgesetzt";
        case "individualRulingRequired":
          return "Einzelfallentscheidung erforderlich";
        case "notApplicable":
          return "Zielvorgabe nicht anwendbar";
        case "unknown":
          return "Zielvorgabe unbekannt";
        default:
          return "Fehler";
      }
    },
    getPerformanceTargetStatus(performanceTarget, user) {
      const performanceTargetResults = performanceTarget.matchedResults;

      return performanceTargetResults.find(
        (result) => result.user.uid === user.user.uid
      );
      // from performanceTargetResults
      // const emptyDeadlinesStatus = {
      //   meta: {
      //     uid: user.user.uid,
      //     lastUpdatedBy: "",
      //   },
      //   status: {
      //     id: "0",
      //   },
      // };
      // console.log("Deadline:", deadline);

      // const response = performanceTarget.responses.find(
      //   (response) => response.meta.uid === user.user.uid
      // );

      // if (!response) {
      //   emptyDeadlinesStatus.status.id = "-1";
      // }

      // return response ? response : emptyDeadlinesStatus;
      // return emptyDeadlinesStatus;
    },
    getSummaryCount() {
      // const possibleUsers = this.memberships.filter((user) => {
      //   return (
      //     this.isUserInvited(event, user) && this.isUserActive(event, user)
      //   );
      // });
      // const possibleUsersWithoutResponses = possibleUsers.filter((user) => {
      //   return !event.responses.find(
      //     (response) => response.meta.uid === user.user.uid
      //   );
      // });
      // return {
      //   totalResponses: event.responses.length,
      //   totalConfirmations: event.responses.filter(
      //     (response) => response.status.id === "1"
      //   ).length,
      //   totalCancellations: event.responses.filter(
      //     (response) => response.status.id === "2"
      //   ).length,
      //   totalIfNecessary: event.responses.filter(
      //     (response) => response.status.id === "3"
      //   ).length,
      //   totalNotInTown: event.responses.filter(
      //     (response) => response.status.id === "4"
      //   ).length,
      //   totalAwaitingResponse: possibleUsersWithoutResponses.length,
      // };
    },
  },
};
</script>
