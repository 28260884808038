<template>
  <div>
    <NavigationBar
      titel="Ausnahme anlegen"
      showBackBtn
      :actions="actions"
    ></NavigationBar>
    <section>
      <v-form
        @submit.prevent="createItem"
        ref="form"
        v-model="helpers.formIsValid"
      >
        <performance-target-exception-editor
          v-model="performanceTargetException"
          mode="new"
        ></performance-target-exception-editor>
      </v-form>
    </section>
  </div>
</template>

<script>
import { Timestamp } from "@/firebase";
import { MEMBER_MANAGEMENT } from "@/store/modules.js";
import { CREATE_PERFORMANCE_TARGET_EXCEPTION } from "@/store/action-types.js";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import PerformanceTargetExceptionEditor from "@/components/member-management/performance-targets/PerformanceTargetExceptionEditor.vue";

export default {
  name: "member-management-performance-targets-exceptions-new",
  components: {
    NavigationBar,
    PerformanceTargetExceptionEditor,
  },
  data() {
    return {
      actions: [
        {
          title: "Erstellen",
          icon: "mdi-plus-circle",
          actionStyle: "textBtnSubmit",
          function: this.createItem,
        },
      ],
      performanceTargetException: {
        reason: "",
        user: {
          uid: "",
          membershipId: "",
          displayName: "",
          firstName: "",
          lastName: "",
        },
        applicablePerformanceTargets: {
          ids: [],
          data: [],
        },
        validFrom: { date: null, time: "00:00" },
        validUntil: { date: null, time: "23:59" },
      },
      helpers: {
        formIsValid: false,
      },
    };
  },
  computed: {},
  created() {
    this.initialize();
  },
  methods: {
    initialize() {},
    validate() {
      this.$refs.form.validate();
    },
    createItem() {
      this.validate();
      if (this.helpers.formIsValid) {
        this.$store.dispatch(
          `${MEMBER_MANAGEMENT}${CREATE_PERFORMANCE_TARGET_EXCEPTION}`,
          {
            organizationId: this.$route.params.organizationId,

            reason: this.performanceTargetException.reason,
            user: {
              uid: this.performanceTargetException.user.uid,
              membershipId: this.performanceTargetException.user.membershipId,
              displayName: this.performanceTargetException.user.displayName,
              firstName: this.performanceTargetException.user.firstName,
              lastName: this.performanceTargetException.user.lastName,
            },
            validFrom: {
              timestamp: this.convertToTimestamp(
                this.performanceTargetException.validFrom.date,
                this.performanceTargetException.validFrom.time
              ),
            },
            validUntil: {
              timestamp: this.convertToTimestamp(
                this.performanceTargetException.validUntil.date,
                this.performanceTargetException.validUntil.time
              ),
            },
            applicablePerformanceTargets: {
              ids: this.performanceTargetException.applicablePerformanceTargets.data.map(
                (target) => target.id
              ),
              data: this.performanceTargetException.applicablePerformanceTargets
                .data,
            },
          }
        );
      } else {
        alert("Prüfe Deine Eingabe und versuche es erneut.");
      }
    },
    convertToTimestamp(date, time) {
      const input = date + " " + time;
      return Timestamp.fromDate(new Date(input.replace(/-/g, "/")));
    },
  },
};
</script>

<style></style>
