<template>
  <div>
    <v-data-iterator
      :items="results"
      sort-by="user.lastName"
      :search="search"
      :sort-desc="false"
      :items-per-page="25"
      :custom-filter="filter"
      :footer-props="footerProps"
      :group-by.sync="groupBySelector.value"
    >
      <template v-slot:default="props">
        <v-card flat>
          <div v-for="(group, index) in props.groupedItems" :key="index">
            <v-divider></v-divider>
            <v-subheader
              :class="
                $vuetify.theme.dark
                  ? 'font-weight-medium'
                  : 'grey lighten-5 font-weight-medium'
              "
              style="height: 32px"
            >
              {{ stripPrefix(group.name) }}
            </v-subheader>
            <v-divider></v-divider>
            <div v-for="(result, i) in group.items" :key="result.user.uid">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-medium">{{
                    result.user.displayName
                  }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <performance-target-status-chip
                    :status="result.totalPerformanceTargetResultStatus"
                    small
                  ></performance-target-status-chip>
                </v-list-item-action>
              </v-list-item>
              <v-divider v-if="group.items.length !== i + 1"></v-divider>
            </div>
          </div>
        </v-card>
      </template>
    </v-data-iterator>
  </div>
</template>

<script>
import PerformanceTargetStatusChip from "@/components/member-management/performance-targets/PerformanceTargetStatusChip.vue";
export default {
  name: "performance-targets-results-list",
  props: {
    results: {
      type: Array,
      required: true,
    },
    search: {
      type: String,
      required: false,
    },
    groupBySelector: {
      type: Object,
      required: true,
    },
  },
  components: {
    PerformanceTargetStatusChip,
  },
  data() {
    return {
      helpers: {
        dataIsLoading: false,
      },
      footerProps: {
        "items-per-page-options": [15, 25, 50, 100, -1],
      },
    };
  },
  computed: {
    reports() {
      return this.$store.state.dutyManagement.reports;
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {},
    filter(items, search) {
      if (!search) return items;
      const searchLower = search.toLowerCase();
      return items.filter((item) => {
        return Object.values(item).some((prop) => {
          if (typeof prop === "object" && prop !== null) {
            return Object.values(prop).some((subProp) =>
              String(subProp).toLowerCase().includes(searchLower)
            );
          }
          return String(prop).toLowerCase().includes(searchLower);
        });
      });
    },
    stripPrefix(item) {
      return item.substring(item.indexOf("_") + 1);
    },
  },
};
</script>
