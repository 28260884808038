<template>
  <div>
    <NavigationBar
      titel="Ausnahme bearbeiten"
      showBackBtn
      :actions="actions"
    ></NavigationBar>
    <section>
      <v-form
        @submit.prevent="updateItem"
        ref="form"
        v-model="helpers.formIsValid"
      >
        <performance-target-exception-editor
          v-model="performanceTargetException"
          mode="edit"
        ></performance-target-exception-editor>
      </v-form>
    </section>
  </div>
</template>

<script>
import { db, Timestamp } from "@/firebase";
import { MEMBER_MANAGEMENT } from "@/store/modules.js";
import { UPDATE_PERFORMANCE_TARGET_EXCEPTION } from "@/store/action-types.js";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import PerformanceTargetExceptionEditor from "@/components/member-management/performance-targets/PerformanceTargetExceptionEditor.vue";

export default {
  name: "member-management-performance-targets-exceptions-edit",
  components: {
    NavigationBar,
    PerformanceTargetExceptionEditor,
  },
  data() {
    return {
      actions: [
        {
          title: "Speichern",
          icon: "mdi-content-save",
          actionStyle: "textBtnSubmit",
          function: this.updateItem,
        },
      ],
      performanceTargetException: null,

      helpers: {
        formIsValid: false,
      },
    };
  },
  computed: {},
  created() {
    this.initialize();
  },
  watch: {
    $route: "initialize",
  },
  methods: {
    initialize() {
      db.collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("performanceTargetExceptions")
        .doc(this.$route.params.itemId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            this.performanceTargetException = doc.data();
            (this.performanceTargetException.validFrom.date =
              this.convertToDate(
                this.performanceTargetException.validFrom.timestamp
              )),
              (this.performanceTargetException.validFrom.time =
                this.convertToTime(
                  this.performanceTargetException.validFrom.timestamp
                )),
              (this.performanceTargetException.validUntil.date =
                this.convertToDate(
                  this.performanceTargetException.validUntil.timestamp
                )),
              (this.performanceTargetException.validUntil.time =
                this.convertToTime(
                  this.performanceTargetException.validUntil.timestamp
                ));
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          return false;
        });
    },
    convertToDate(date) {
      date = date.toDate();
      return (
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1 < 10 ? "0" : "") +
        (date.getMonth() + 1) +
        "-" +
        (date.getDate() < 10 ? "0" : "") +
        date.getDate()
      );
    },
    convertToTime(time) {
      time = time.toDate();
      return (
        (time.getHours() < 10 ? "0" : "") +
        time.getHours() +
        ":" +
        (time.getMinutes() < 10 ? "0" : "") +
        time.getMinutes()
      );
    },
    validate() {
      this.$refs.form.validate();
    },
    updateItem() {
      this.validate();

      if (this.helpers.formIsValid) {
        var res = confirm(
          "Bist Du sicher, dass Du die Ausnahme aktualisieren möchtest. Bevorstehende Berechnungen werden mit der angepassten Version vorgenommen. Alte Berechnungen werden nicht aktualisiert, sofern keine manuelle Anforderung erfolgt. Soll die Änderung erst zu einem bestimmten Zeitpunkt erfolgen, wird empfohlen bis zu diesem Zeitpunkt abzuwarten und dann eine manuelle Neuberechnung durchzuführen.\n\nSoll die Aktualisierung trotzdem ausgeführt werden?"
        );
        if (res == true) {
          this.$store.dispatch(
            `${MEMBER_MANAGEMENT}${UPDATE_PERFORMANCE_TARGET_EXCEPTION}`,
            {
              organizationId: this.$route.params.organizationId,
              itemId: this.$route.params.itemId,

              reason: this.performanceTargetException.reason,
              validFrom: {
                timestamp: this.convertToTimestamp(
                  this.performanceTargetException.validFrom.date,
                  this.performanceTargetException.validFrom.time
                ),
              },
              validUntil: {
                timestamp: this.convertToTimestamp(
                  this.performanceTargetException.validUntil.date,
                  this.performanceTargetException.validUntil.time
                ),
              },
              applicablePerformanceTargets: {
                ids: this.performanceTargetException
                  .applicablePerformanceTargets.ids,
                data: this.performanceTargetException
                  .applicablePerformanceTargets.data,
              },
            }
          );
        }
      } else {
        alert("Prüfe Deine Eingabe und versuche es erneut.");
      }
    },
    convertToTimestamp(date, time) {
      const input = date + " " + time;
      return Timestamp.fromDate(new Date(input.replace(/-/g, "/")));
    },
  },
};
</script>
