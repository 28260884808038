<template>
  <div>
    <v-skeleton-loader
      v-if="loading"
      elevation="2"
      type="article, divider, list-item-avatar, list-item-avatar, list-item-avatar"
    ></v-skeleton-loader>
    <v-card v-else>
      <!-- :to="{
        name: 'member-management-performance-targets-details',
        params: {
          organizationId: $route.params.organizationId,
          itemId: performanceTarget.meta?.id,
        },
      }" -->
      <v-card-title>{{ performanceTarget.title }}</v-card-title>
      <!-- <v-card-subtitle>
                            <span>
                              <v-icon small>mdi-sort-numeric-ascending</v-icon>
                              {{ performanceTarget.sortKey }}
                            </span>
                          </v-card-subtitle> -->
      <v-card-text>
        <v-row class="match-height" dense>
          <v-col cols="12" sm="12" md="3">
            <v-card outlined>
              <v-card-subtitle> Aktuelle Erfüllung </v-card-subtitle>
              <v-card-text>
                <!-- <v-row dense>
                  <v-col cols="12">
                    <v-card color="green lighten-4" dark outlined>
                      <v-card-title class="py-1">
                        <div
                          class="font-weight-medium subtitle-2 text--darken-4 green--text"
                        >
                          Erfüllt
                        </div>
                        <v-spacer></v-spacer>
                        <div
                          class="font-weight-medium text-h5 text--darken-4 green--text"
                        >
                          {{ value }}
                        </div>
                      </v-card-title>
                    </v-card>
                  </v-col>
                  <v-col cols="12">
                    <v-card color="orange lighten-4" dark outlined>
                      <v-card-title class="py-1">
                        <div
                          class="font-weight-medium subtitle-2 text--darken-4 orange--text"
                        >
                          Nicht anwendbar
                        </div>
                        <v-spacer></v-spacer>
                        <div
                          class="font-weight-medium text-h5 text--darken-4 orange--text"
                        >
                          {{ value }}
                        </div>
                      </v-card-title>
                    </v-card>
                  </v-col>
                  <v-col cols="12">
                    <v-card color="red lighten-4" dark outlined>
                      <v-card-title class="py-1">
                        <div
                          class="font-weight-medium subtitle-2 text--darken-4 red--text"
                        >
                          Nicht erfüllt
                        </div>
                        <v-spacer></v-spacer>
                        <div
                          class="font-weight-medium text-h5 text--darken-4 red--text"
                        >
                          {{ value }}
                        </div>
                      </v-card-title>
                    </v-card>
                  </v-col>
                </v-row> -->
                <v-row dense>
                  <v-col cols="6">
                    <v-card class="pa-2" color="green lighten-4" dark outlined>
                      <div
                        class="font-weight-medium text-h5 text-center text--darken-4 green--text"
                      >
                        {{ value }}
                      </div>
                      <div
                        class="font-weight-medium subtitle-2 text-center text--darken-4 green--text"
                      >
                        Erfüllt
                      </div>
                    </v-card>
                  </v-col>
                  <v-col cols="6">
                    <v-card class="pa-2" color="red lighten-4" dark outlined>
                      <div
                        class="font-weight-medium text-h5 text-center text--darken-4 red--text"
                      >
                        {{ value }}
                      </div>
                      <div
                        class="font-weight-medium subtitle-2 text-center text--darken-4 red--text"
                      >
                        Nicht erfüllt
                      </div>
                    </v-card>
                  </v-col>
                  <v-col cols="6">
                    <v-card class="pa-2" color="orange lighten-4" dark outlined>
                      <div
                        class="font-weight-medium text-h5 text-center text--darken-4 orange--text"
                      >
                        {{ value }}
                      </div>
                      <div
                        class="font-weight-medium subtitle-2 text-center text--darken-4 orange--text"
                      >
                        Nicht anwendbar
                      </div>
                    </v-card>
                  </v-col>
                  <v-col cols="6">
                    <v-card class="pa-2" color="indigo lighten-4" dark outlined>
                      <div
                        class="font-weight-medium text-h5 text-center text--darken-4 indigo--text"
                      >
                        {{ value }}
                      </div>
                      <div
                        class="font-weight-medium subtitle-2 text-center text--darken-4 indigo--text"
                      >
                        Nicht anwendbar
                      </div>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="12" md="9">
            <v-card outlined>
              <v-card-subtitle>
                Erfüllung letzte 14 Tage (DD.MM.YYYY bis DD.MM.YYYY)
              </v-card-subtitle>
              <apexchart
                type="area"
                height="200"
                :options="performanceTarget.chartOptions"
                :series="performanceTarget.series"
              ></apexchart>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
export default {
  name: "member-management-performance-targets",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    performanceTarget: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      value: 0,
    };
  },
  methods: {},
  computed: {},
};
</script>
