var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('section',[[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"4","hidden":""}},[_c('self-service-performance-monitoring',{attrs:{"title":"Mein Erfüllungsstatus","hideDetailsBtn":""}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-subtitle',[_c('v-row',[_c('v-col',{attrs:{"cols":"4","sm":"5","md":"3"}},[_c('v-text-field',{attrs:{"outlined":"","append-icon":"mdi-magnify","label":"Suchen","hide-details":"auto","clearable":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticClass:"d-flex justify-end"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"outlined":"","text":""}},'v-btn',attrs,false),on),[_c('span',[_vm._v(" Gruppieren nach "),_c('strong',{staticClass:"hidden-sm-and-down"},[_vm._v(_vm._s(_vm.groupBySelector.text))]),_c('v-icon',{staticClass:"hidden-md-and-up",attrs:{"small":""}},[_vm._v(_vm._s(_vm.groupBySelector.icon))])],1),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-menu-down")])],1)]}}])},[_c('v-list',{attrs:{"nav":""}},[_c('v-list-item-group',{attrs:{"mandatory":""},model:{value:(_vm.groupBySelector),callback:function ($$v) {_vm.groupBySelector=$$v},expression:"groupBySelector"}},[_c('v-list-item',{attrs:{"link":"","dense":"","value":{
                              text: 'Zielvorgabe',
                              value: 'groupByStatus',
                              icon: 'mdi-bullseye-arrow',
                            }}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"dense":""}},[_vm._v("mdi-bullseye-arrow")])],1),_c('v-list-item-title',[_vm._v("Zielvorgabe ")])],1),_c('v-list-item',{attrs:{"link":"","dense":"","value":{
                              text: 'Benutzergruppe',
                              value: 'groupByGroup',
                              icon: 'mdi-account-group',
                            }}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"dense":""}},[_vm._v("mdi-account-group")])],1),_c('v-list-item-title',[_vm._v("Benutzergruppe")])],1)],1)],1)],1),_c('v-btn-toggle',{staticClass:"ml-2",attrs:{"dense":"","mandatory":""},model:{value:(_vm.helpers.displayMode),callback:function ($$v) {_vm.$set(_vm.helpers, "displayMode", $$v)},expression:"helpers.displayMode"}},[_c('v-btn',{attrs:{"outlined":"","icon":"","value":"list"}},[_c('v-icon',{attrs:{"color":_vm.$vuetify.theme.dark ? '' : 'grey darken-1'}},[_vm._v("mdi-view-sequential-outline")])],1),_c('v-btn',{attrs:{"depressed":"","outlined":"","icon":"","value":"table"}},[_c('v-icon',{attrs:{"color":_vm.$vuetify.theme.dark ? '' : 'grey darken-1'}},[_vm._v("mdi-table")])],1)],1)],1)],1)],1),(_vm.helpers.displayMode === 'table')?_c('v-divider'):_vm._e(),(_vm.helpers.displayMode === 'list')?_c('performance-targets-results-list',{attrs:{"results":_vm.performanceTargetsWithResults,"search":_vm.search,"group-by-selector":_vm.groupBySelector}}):(_vm.helpers.displayMode === 'table')?_c('performance-targets-results-data-table',{attrs:{"results":_vm.performanceTargetsWithResults,"search":_vm.search,"group-by-selector":_vm.groupBySelector}}):_vm._e()],1)],1)],1)],1)]],2),(_vm.checkIfSupport)?_c('section',[_c('support-tools',{attrs:{"sources":_vm.code}})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }