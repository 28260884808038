<template>
  <div>
    <template>
      <v-container>
        <v-row>
          <v-col cols="12">
            <Subheader icon="mdi-card-text" title="Allgemein"></Subheader>
            <div class="mt-7">
              <v-card>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model.trim="performanceTargetExceptionLocal.reason"
                          clearable
                          label="Grund"
                          :rules="[rules.required]"
                          outlined
                        ></v-text-field
                      ></v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </div>
          </v-col>
          <v-col cols="12">
            <Subheader icon="mdi-cog" title="Einstellungen"></Subheader>
            <div class="mt-7">
              <v-card>
                <v-card-text>
                  <v-container>
                    <v-row>Gültig ab:</v-row>
                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="
                            performanceTargetExceptionLocal.validFrom.date
                          "
                          type="date"
                          label="Startdatum"
                          hint="DD.MM.YYYY"
                          outlined
                          :rules="[rules.required, rules.startBeforeEnd]"
                          required
                          prepend-inner-icon="mdi-calendar-start"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="3">
                        <v-text-field
                          v-model="
                            performanceTargetExceptionLocal.validFrom.time
                          "
                          label="Startzeit"
                          hint="HH:MM"
                          :rules="[rules.required, rules.startBeforeEnd]"
                          outlined
                          type="time"
                          prepend-inner-icon="mdi-clock-start"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>Gültig bis:</v-row>
                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="
                            performanceTargetExceptionLocal.validUntil.date
                          "
                          type="date"
                          label="Enddatum"
                          hint="DD.MM.YYYY"
                          outlined
                          :rules="[rules.required, rules.startBeforeEnd]"
                          required
                          prepend-inner-icon="mdi-calendar-end"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="3">
                        <v-text-field
                          v-model="
                            performanceTargetExceptionLocal.validUntil.time
                          "
                          label="Endzeit"
                          hint="HH:MM"
                          outlined
                          :rules="[rules.required, rules.startBeforeEnd]"
                          type="time"
                          prepend-inner-icon="mdi-clock-end"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>Anwendbar auf:</v-row>
                    <v-row>
                      <v-col cols="12" sm="6" md="6">
                        <v-select
                          v-model="
                            performanceTargetExceptionLocal
                              .applicablePerformanceTargets.data
                          "
                          :items="performanceTargets"
                          label="Zielvorgaben"
                          outlined
                          multiple
                          return-object
                          small-chips
                          :rules="[rules.required]"
                          item-value="id"
                          item-text="title"
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </div>
</template>

<script>
import Subheader from "@/components/_systemwide/Subheader.vue";
import { MEMBER_MANAGEMENT } from "@/store/modules.js";
import { GET_PERFORMANCE_TARGETS } from "@/store/action-types.js";
export default {
  name: "performance-target-exception-editor",
  props: {
    performanceTargetException: {},
    mode: {
      type: String,
      default: "new",
      required: true,
      validator: function (value) {
        return ["new", "edit"].indexOf(value) !== -1;
      },
    },
  },
  components: {
    Subheader,
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || "Feld ist erforderlich.",
      },
    };
  },
  model: {
    prop: "performanceTargetException",
    event: "performanceTargetExceptionChange",
  },
  computed: {
    performanceTargetExceptionLocal: {
      get: function () {
        return this.performanceTargetException;
      },
      set: function (value) {
        this.$emit("performanceTargetExceptionChange", value);
      },
    },
    performanceTargets() {
      return this.$store.state.memberManagement.performanceTargets;
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.$store.dispatch(`${MEMBER_MANAGEMENT}${GET_PERFORMANCE_TARGETS}`, {
        organizationId: this.$route.params.organizationId,
      });
    },
  },
};
</script>
