<template>
  <div>
    <NavigationBar
      titel="Zielvorgaben"
      :tabs="tabs"
      :activeTab="activeTab"
    ></NavigationBar>
    <router-view />
  </div>
</template>

<script>
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";

export default {
  name: "member-management-performance-targets",
  components: {
    NavigationBar,
  },
  data() {
    return {
      activeTab: `overview`,
      tabs: [
        { id: 1, name: "Übersicht", route: `overview` },
        { id: 3, name: "Historie", route: `history` },
        { id: 4, name: "Ausnahmen", route: `exceptions` },
      ],
    };
  },
  computed: {},
};
</script>
