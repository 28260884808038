<template>
  <div>
    <section>
      <TippCard
        hintID="Mitgliederverwaltung_Zielvorgaben_Ausnahmen_1_V1"
        text="Werden Ausnahmen für einzelne Mitglieder angelegt, werden diese bei der Berechnung der Zielvorgaben berücksichtigt."
      >
      </TippCard>
    </section>
    <section>
      <template>
        <v-container fluid>
          <v-row>
            <v-col cols="12">
              <Subheader
                icon="mdi-progress-star"
                title="Ausnahmen"
                :actions="actions"
              ></Subheader>
              <div class="mt-7">
                <v-data-table
                  :items="exceptions"
                  :headers="headers"
                  :sort-by="sortBy.toLowerCase()"
                  :sort-desc="sortDesc"
                  group-by="groupByTitle"
                  item-key="meta.id"
                  single-expand
                  show-expand
                  :expanded.sync="expanded"
                  class="elevation-2"
                  no-data-text="Keine Ausnahmen vorhanden"
                >
                  <template v-slot:group.header="{ items, isOpen, toggle }">
                    <th :colspan="headers.length">
                      <v-icon @click="toggle" class="mr-1"
                        >{{ isOpen ? "mdi-chevron-up" : "mdi-chevron-down" }}
                      </v-icon>
                      {{ stripPrefix(items[0].groupByTitle) }}
                    </th>
                  </template>
                  <template v-slot:item.title="{ item }">
                    <div class="d-flex flex-column">
                      {{ item.title }}
                      <small class="grey--text">
                        <span>{{ item.shortTitle }}</span>
                      </small>
                    </div>
                  </template>
                  <template v-slot:item.isActive="{ item }">
                    <v-simple-checkbox
                      v-model="item.isActive"
                      color="primary"
                      disabled
                    ></v-simple-checkbox>
                  </template>
                  <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length">
                      <v-card-text>
                        <v-row dense>
                          <v-col cols="12">
                            <v-card outlined>
                              <v-card-subtitle>Variablen</v-card-subtitle>
                              <div
                                v-for="variable in item.customVariables"
                                :key="variable.id"
                              >
                                <v-divider></v-divider>
                                <v-list-item>
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      <code
                                        v-text="variable.options.pseudoId"
                                        class="mr-2"
                                      ></code
                                      >{{ variable.options.label
                                      }}<span v-if="variable.options.isRequired"
                                        >*</span
                                      >
                                    </v-list-item-title>
                                    <v-list-item-subtitle>{{
                                      variable.options.hint
                                    }}</v-list-item-subtitle>
                                  </v-list-item-content>
                                </v-list-item>
                              </div>
                              <div v-if="!item.customVariables.length">
                                <v-divider></v-divider>
                                <v-card-text class="grey--text"
                                  >Keine Variablen vorhanden.</v-card-text
                                >
                              </div>
                            </v-card>
                          </v-col>
                          <v-col cols="12">
                            <v-card outlined>
                              <v-card-subtitle>Formel</v-card-subtitle>
                              <v-divider></v-divider>
                              <v-list-item>
                                <v-list-item-content>
                                  <v-list-item-title>
                                    <code v-text="item.formula"></code>
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </v-card>
                          </v-col>
                          <v-col cols="12" sm="6" md="3">
                            <v-card outlined>
                              <v-list-item>
                                <v-list-item-content>
                                  <v-list-item-title>{{
                                    getTimeRoundingTypeText(
                                      item.timeRoundingType
                                    )
                                  }}</v-list-item-title>
                                  <v-list-item-subtitle
                                    >Zeit runden auf</v-list-item-subtitle
                                  >
                                </v-list-item-content>
                              </v-list-item>
                            </v-card>
                          </v-col>
                          <v-col cols="12" sm="6" md="3">
                            <v-card outlined>
                              <v-list-item>
                                <v-list-item-content>
                                  <v-list-item-title>{{
                                    timestampConverter(
                                      item.validDuration.start.timestamp
                                    )
                                  }}</v-list-item-title>
                                  <v-list-item-subtitle
                                    >Verwendung zulässig
                                    ab</v-list-item-subtitle
                                  >
                                </v-list-item-content>
                              </v-list-item>
                            </v-card>
                          </v-col>
                          <v-col cols="12" sm="6" md="3">
                            <v-card outlined>
                              <v-list-item>
                                <v-list-item-content>
                                  <v-list-item-title>{{
                                    timestampConverter(
                                      item.validDuration.end.timestamp
                                    )
                                  }}</v-list-item-title>
                                  <v-list-item-subtitle
                                    >Verwendung zulässig
                                    bis</v-list-item-subtitle
                                  >
                                </v-list-item-content>
                              </v-list-item>
                            </v-card>
                          </v-col>
                          <v-col cols="12" sm="6" md="3">
                            <v-card outlined>
                              <v-list-item>
                                <v-list-item-content>
                                  <v-list-item-title> lorem </v-list-item-title>
                                  <v-list-item-subtitle
                                    >Auszahlungsfälligkeit</v-list-item-subtitle
                                  >
                                </v-list-item-content>
                              </v-list-item>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-card-actions class="px-4 pt-0 mt-n2 mb-2"
                        ><v-btn text outlined class="mr-2" disabled
                          ><v-icon left small>mdi-pencil</v-icon
                          >Bearbeiten</v-btn
                        ><v-btn text outlined color="error" disabled
                          ><v-icon left small>mdi-trash-can</v-icon
                          >Löschen</v-btn
                        ></v-card-actions
                      >
                    </td>
                  </template>
                </v-data-table>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
    <section v-if="checkIfSupport">
      <support-tools :sources="code"></support-tools>
    </section>
  </div>
</template>

<script>
import TippCard from "@/components/_systemwide/Tipp_Card.vue";
import Subheader from "@/components/_systemwide/Subheader.vue";
import SupportTools from "@/components/_system/helpers/SupportTools.vue";
import { MEMBER_MANAGEMENT } from "@/store/modules.js";
import { GET_EXCEPTIONS } from "@/store/action-types.js";
import { MEMBER_MANAGEMENTS_PERFORMANCE_TARGETS_EXCEPTIONS_create } from "@/data/permission-types.js";

export default {
  name: "member-management-performance-targets-exceptions",
  components: {
    TippCard,
    Subheader,
    SupportTools,
  },
  data() {
    return {
      sortDesc: false,
      sortBy: "sortKey",

      actions: [
        {
          title: "Erstellen",
          icon: "mdi-playlist-plus",
          permission: `${MEMBER_MANAGEMENTS_PERFORMANCE_TARGETS_EXCEPTIONS_create}`,
          actionStyle: "textBtn",
          function: () => {
            this.$router.push({
              name: "member-management-performance-targets-exceptions-new",
              params: {
                organizationId: this.$route.params.organizationId,
              },
            });
          },
        },
      ],

      headers: [
        {
          text: "Vorname",
          value: "user.firstName",
          align: "start",
        },
        {
          text: "Nachname",
          value: "user.lastName",
          align: "start",
        },
        {
          text: "Grund",
          value: "reason",
        },
        {
          text: "Gültigkeitsbeginn",
          value: "validFrom.timestamp",
        },
        {
          text: "Gültigkeitsende",
          value: "validUntil.timestamp",
        },
        { text: "", value: "data-table-expand", width: "1%" },
      ],
      expanded: [],
      helpers: {},
    };
  },
  computed: {
    exceptions() {
      return this.$store.state.memberManagement.exceptions;
    },
    checkIfSupport() {
      return this.$store.getters["organization/checkIfSupport"];
    },
    code() {
      return [{ title: "exceptions", data: this.exceptions }];
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.$store.dispatch(`${MEMBER_MANAGEMENT}${GET_EXCEPTIONS}`, {
        organizationId: this.$route.params.organizationId,
      });
    },
    timestampConverter(timestamp) {
      const options = {
        weekday: "short",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      };
      return timestamp.toDate().toLocaleDateString("de-DE", options);
    },
  },
};
</script>
