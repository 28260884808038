var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('Subheader',{attrs:{"icon":"mdi-card-text","title":"Allgemein"}}),_c('div',{staticClass:"mt-7"},[_c('v-card',[_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"clearable":"","label":"Grund","rules":[_vm.rules.required],"outlined":""},model:{value:(_vm.performanceTargetExceptionLocal.reason),callback:function ($$v) {_vm.$set(_vm.performanceTargetExceptionLocal, "reason", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"performanceTargetExceptionLocal.reason"}})],1)],1)],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('Subheader',{attrs:{"icon":"mdi-cog","title":"Einstellungen"}}),_c('div',{staticClass:"mt-7"},[_c('v-card',[_c('v-card-text',[_c('v-container',[_c('v-row',[_vm._v("Gültig ab:")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-text-field',{attrs:{"type":"date","label":"Startdatum","hint":"DD.MM.YYYY","outlined":"","rules":[_vm.rules.required, _vm.rules.startBeforeEnd],"required":"","prepend-inner-icon":"mdi-calendar-start"},model:{value:(
                          _vm.performanceTargetExceptionLocal.validFrom.date
                        ),callback:function ($$v) {_vm.$set(_vm.performanceTargetExceptionLocal.validFrom, "date", $$v)},expression:"\n                          performanceTargetExceptionLocal.validFrom.date\n                        "}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-text-field',{attrs:{"label":"Startzeit","hint":"HH:MM","rules":[_vm.rules.required, _vm.rules.startBeforeEnd],"outlined":"","type":"time","prepend-inner-icon":"mdi-clock-start"},model:{value:(
                          _vm.performanceTargetExceptionLocal.validFrom.time
                        ),callback:function ($$v) {_vm.$set(_vm.performanceTargetExceptionLocal.validFrom, "time", $$v)},expression:"\n                          performanceTargetExceptionLocal.validFrom.time\n                        "}})],1)],1),_c('v-row',[_vm._v("Gültig bis:")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-text-field',{attrs:{"type":"date","label":"Enddatum","hint":"DD.MM.YYYY","outlined":"","rules":[_vm.rules.required, _vm.rules.startBeforeEnd],"required":"","prepend-inner-icon":"mdi-calendar-end"},model:{value:(
                          _vm.performanceTargetExceptionLocal.validUntil.date
                        ),callback:function ($$v) {_vm.$set(_vm.performanceTargetExceptionLocal.validUntil, "date", $$v)},expression:"\n                          performanceTargetExceptionLocal.validUntil.date\n                        "}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-text-field',{attrs:{"label":"Endzeit","hint":"HH:MM","outlined":"","rules":[_vm.rules.required, _vm.rules.startBeforeEnd],"type":"time","prepend-inner-icon":"mdi-clock-end"},model:{value:(
                          _vm.performanceTargetExceptionLocal.validUntil.time
                        ),callback:function ($$v) {_vm.$set(_vm.performanceTargetExceptionLocal.validUntil, "time", $$v)},expression:"\n                          performanceTargetExceptionLocal.validUntil.time\n                        "}})],1)],1),_c('v-row',[_vm._v("Anwendbar auf:")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-select',{attrs:{"items":_vm.performanceTargets,"label":"Zielvorgaben","outlined":"","multiple":"","return-object":"","small-chips":"","rules":[_vm.rules.required],"item-value":"id","item-text":"title"},model:{value:(
                          _vm.performanceTargetExceptionLocal
                            .applicablePerformanceTargets.data
                        ),callback:function ($$v) {_vm.$set(_vm.performanceTargetExceptionLocal
                            .applicablePerformanceTargets, "data", $$v)},expression:"\n                          performanceTargetExceptionLocal\n                            .applicablePerformanceTargets.data\n                        "}})],1)],1)],1)],1)],1)],1)],1)],1)],1)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }