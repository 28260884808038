<template>
  <div>
    <NavigationBar
      titel=""
      dense
      :actions="actions"
      style="
        border-top-width: thin !important;
        border-color: rgba(0, 0, 0, 0.12);
        border-top-style: solid !important;
      "
    ></NavigationBar>
    <section>
      <TippCard
        hintID="Terminplanung_Konfiguration_Allgemein_1_V1"
        text="Veranstaltungen basieren auf einer Veranstaltungsart. Jeder Veranstaltungsart sind verschiedene Funktionen und Parameter zugewiesen, die sich teilweise auch auf bestehende Veranstaltungen auswirken können. Auf abgeschlossene Veranstaltungen haben Änderungen keine Auswirkung."
      >
      </TippCard>
    </section>
    <section>
      <template>
        <v-container fluid>
          <v-row justify="center" align="center">
            <v-col cols="12">
              <v-data-iterator
                :items="performanceTargetsWithResults"
                sort-by="sortKey"
                no-data-text="Keine Zielvorgaben vorhanden"
              >
                <template v-slot:header>
                  <v-row class="match-height">
                    <v-col cols="12">
                      <performance-target-details-card
                        :performanceTarget="performanceTargetTotal"
                      ></performance-target-details-card>
                    </v-col>
                  </v-row>
                </template>
                <template v-slot:default="props">
                  <v-row class="match-height">
                    <v-col
                      v-for="item in props.items"
                      :key="item.meta.id"
                      cols="12"
                    >
                      <performance-target-details-card
                        :performanceTarget="item"
                      ></performance-target-details-card>
                    </v-col>
                  </v-row>
                </template>
              </v-data-iterator>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
    <v-btn @click="startQuery()">startQuery()</v-btn>
  </div>
</template>

<script>
import { functions } from "@/firebase";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import TippCard from "@/components/_systemwide/Tipp_Card.vue";
import PerformanceTargetDetailsCard from "@/components/member-management/performance-targets/PerformanceTargetDetailsCard.vue";
import { MEMBER_MANAGEMENT } from "@/store/modules.js";
import {
  GET_PERFORMANCE_TARGETS,
  GET_PERFORMANCE_TARGET_RESULTS,
} from "@/store/action-types.js";
import { MEMBER_MANAGEMENT_PERFORMANCE_TARGETS_create } from "@/data/permission-types.js";

export default {
  name: "member-management-performance-targets-history",
  components: {
    NavigationBar,
    TippCard,
    PerformanceTargetDetailsCard,
  },
  data() {
    return {
      actions: [
        {
          title: "Erstellen",
          icon: "mdi-plus",
          permission: `${MEMBER_MANAGEMENT_PERFORMANCE_TARGETS_create}`,
          actionStyle: "textBtn",
          function: () => {
            this.$router.push({
              name: "member-management-performance-targets-new",
              params: {
                organizationId: this.$route.params.organizationId,
              },
            });
          },
        },
      ],
    };
  },
  computed: {
    performanceTargets() {
      return this.$store.state.memberManagement.performanceTargets;
    },
    performanceTargetResults() {
      return this.$store.state.memberManagement.performanceTargetResults;
    },
    performanceTargetTotal() {
      return {
        title: "Insgesamt",
        series: [],
        chartOptions: {},
      };
    },
    performanceTargetsWithResults() {
      return this.performanceTargets.map((performanceTarget) => {
        // Filter results that match the current performance target
        const matchingResults = this.performanceTargetResults.filter((result) =>
          result.performanceTargets.some(
            (pt) => pt.performanceTarget.id === performanceTarget.meta.id
          )
        );
        // Group results by date and status
        const groupedByDateAndStatus = matchingResults.reduce((acc, result) => {
          const timeValue = result.meta.lastCalculatedAt.toDate(); // this is a firestore timestamp
          let date;
          try {
            date = new Date(timeValue).toISOString().split("T")[0]; // From a firestore timestamp get date in YYYY-MM-DD format
          } catch (error) {
            return acc;
          }
          result.performanceTargets.forEach((pt) => {
            if (pt.performanceTarget.id === performanceTarget.meta.id) {
              if (!acc[date]) {
                acc[date] = {};
              }
              if (!acc[date][pt.status]) {
                acc[date][pt.status] = 0;
              }
              acc[date][pt.status]++;
            }
          });
          return acc;
        }, {});
        const statuses = ["met", "notMet", "notApplicable", "unknown"];

        // Prepare series data for apexcharts
        const series = statuses.map((status) => {
          const data = Object.keys(groupedByDateAndStatus)
            .filter(
              (date) => groupedByDateAndStatus[date][status] !== undefined
            )
            .map((date) => ({
              x: new Date(date),
              y: groupedByDateAndStatus[date][status] || 0,
            }));
          return {
            name: this.statusText(status),
            data: data,
          };
        });

        // Calculate the next and past cut-off dates based on the monitoring interval
        const currentDate = new Date();
        let nextCutOffDate = new Date(currentDate);
        let pastCutOffDate = new Date(currentDate);
        switch (performanceTarget.monitoringInterval.unit) {
          case "days":
          case "days+current":
            nextCutOffDate.setDate(
              currentDate.getDate() + performanceTarget.monitoringInterval.value
            );
            pastCutOffDate.setDate(
              currentDate.getDate() - performanceTarget.monitoringInterval.value
            );
            break;
          case "weeks":
          case "weeks+current":
            nextCutOffDate.setDate(
              currentDate.getDate() +
                performanceTarget.monitoringInterval.value * 7
            );
            pastCutOffDate.setDate(
              currentDate.getDate() -
                performanceTarget.monitoringInterval.value * 7
            );
            break;
          case "months":
          case "months+current":
            nextCutOffDate.setMonth(
              currentDate.getMonth() +
                performanceTarget.monitoringInterval.value
            );
            pastCutOffDate.setMonth(
              currentDate.getMonth() -
                performanceTarget.monitoringInterval.value
            );
            break;
          default:
            nextCutOffDate = new Date(currentDate);
            pastCutOffDate = new Date(currentDate);
        }

        const chartOptions = {
          chart: {
            type: "area",
            height: 200,
          },
          xaxis: {
            type: "datetime",
            title: {
              text: "Datum",
            },
          },
          yaxis: {
            title: {
              text: "Anzahl Personen",
            },
          },
          colors: ["#81C784", "#E57373", "#BA68C8", "#FFB74D"], // Custom colors for the series
          annotations: {
            xaxis: [
              {
                x: nextCutOffDate.getTime(),
                borderColor: "#000",
                fillColor: "#FEB019",
                label: {
                  orientation: "horizontal",
                  textAnchor: "start",
                  text: `Next Cut-off: ${
                    nextCutOffDate.toISOString().split("T")[0]
                  }`,
                },
              },
              {
                x: pastCutOffDate.getTime(),
                borderColor: "#000",
                fillColor: "#FEB019",
                label: {
                  orientation: "horizontal",
                  textAnchor: "start",
                  text: `Past Cut-off: ${
                    pastCutOffDate.toISOString().split("T")[0]
                  }`,
                },
              },
            ],
          },
        };

        return {
          ...performanceTarget,
          results: matchingResults,
          series: series,
          chartOptions: chartOptions,
        };
      });
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.$store.dispatch(`${MEMBER_MANAGEMENT}${GET_PERFORMANCE_TARGETS}`, {
        organizationId: this.$route.params.organizationId,
      });
      this.$store.dispatch(
        `${MEMBER_MANAGEMENT}${GET_PERFORMANCE_TARGET_RESULTS}`,
        {
          organizationId: this.$route.params.organizationId,
        }
      );
    },
    statusText(statusId) {
      switch (statusId) {
        case "met":
          return "Zielvorgabe erfüllt";
        case "notMet":
          return "Zielvorgabe nicht erfüllt";
        case "metByIndividualRuling":
          return "Zielvorgabe erfüllt (EA)";
        case "notMetByIndividualRuling":
          return "Zielvorgabe nicht erfüllt (EA)";
        case "fullySuspended":
          return "Zielvorgabe vollständig ausgesetzt";
        case "notStarted":
          return "Zielvorgabe nicht begonnen";
        case "temporarilySuspended":
          return "Zielvorgabe zeitweise ausgesetzt";
        case "individualRulingRequired":
          return "Einzelfallentscheidung erforderlich";
        case "notApplicable":
          return "Zielvorgabe nicht anwendbar";
        case "unknown":
          return "Zielvorgabe unbekannt";
        default:
          return "Fehler";
      }
    },
    startQuery() {
      const callFunction = functions.httpsCallable(
        "memberManagement-computePerformanceTargetResults"
      );
      return callFunction({
        organizationId: this.$route.params.organizationId,
        // membershipUids: ["Y3GARwXfXyOtQwZnilCIZDdsm1W2"],
        isSimulation: false,
      })
        .then((result) => {
          console.log(result);
        })
        .catch((error) => {
          console.error("Error: ", error);
        });
    },
  },
};
</script>
